import { useUserAuth } from "./authContext";

export function useOrganization() {
  const { organization } = useUserAuth();

  return organization;
}

export function useOrganizations() {
  const { organizations } = useUserAuth();

  return organizations;
}

export function useUser() {
  const { user } = useUserAuth();

  return user;
}

export function useUserObject() {
  const { userObject } = useUserAuth();

  return userObject;
}

export function useUserId(): string | undefined {
  const { userObject } = useUserAuth();

  return userObject?.user?.user ?? undefined;
}

export function useAddNewOrganization() {
  const { addNewOrganization } = useUserAuth();

  return addNewOrganization;
}

export function useSetCurrentOrganization() {
  const { setCurrentOrganization } = useUserAuth();

  return setCurrentOrganization;
}
