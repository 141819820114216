import type { User as FirebaseUser } from "firebase/auth";
import { createContext, useContext, useEffect, useState } from "react";
import { isEmpty } from "remeda";

import type { GetUserResponse, Organization } from "../apis/api-types";
import { conduitAPI } from "../apis/conduit-api";
import { CURRENT_ORGANIZATION } from "../constants/localStorage";
import useAssignContexts from "../hooks/useAssignContexts";
import { useEvent } from "../hooks/useEvent";
import { logOut, onAuthStateChanged } from "../lib/firebase";
import { initialState, useAuthStore } from "../stores/AuthStore";
import { getCurrentOrganization } from "../utils/getCurrentOrganization";
import { getNameFromUserObject } from "../utils/getNameFromUserObject";

type EmptyObject = Record<string, never>;

type TAuthContext = {
  user: FirebaseUser | EmptyObject | null;
  userObject: GetUserResponse | EmptyObject;

  organization: Organization | EmptyObject;
  organizations: Organization[];

  setCurrentOrganization(org: Organization | EmptyObject): void;
  addNewOrganization(org: Organization): void;
};

const AuthContext = createContext<TAuthContext>({} as TAuthContext);

export function AuthContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const assignContexts = useAssignContexts();

  const [user, setUser] = useState<FirebaseUser | EmptyObject | null>({});
  const [userObject, setUserObject] = useState<GetUserResponse | EmptyObject>(
    {},
  );

  const [organization, setOrganization] = useState<Organization | EmptyObject>(
    {},
  );
  const [organizations, setOrganizations] = useState<Organization[]>([]);

  function setCurrentOrganization(org: Organization | EmptyObject) {
    try {
      if (isEmpty(org as Record<string, never>)) {
        window.localStorage.removeItem(CURRENT_ORGANIZATION);
      } else {
        window.localStorage.setItem(CURRENT_ORGANIZATION, org.organization);
      }
    } catch (error) {
      console.error(error);
    }

    setOrganization(org);
  }

  function addNewOrganization(org: Organization) {
    if (
      organizations.filter((o) => org.organization === o.organization)
        .length === 0
    ) {
      setOrganizations((old) => [...old, org]);
    }
  }

  function clearUserData() {
    useAuthStore.setState(initialState);

    setUserObject({});
    setCurrentOrganization({});
    setOrganizations([]);
  }

  const authStateChanged = useEvent(
    async (currentUser: FirebaseUser | null) => {
      setUser(currentUser);
      useAuthStore.setState({ user: currentUser });

      if (currentUser) {
        try {
          const userJson = await conduitAPI.loadUser();

          useAuthStore.setState({
            email: userJson.user.email,
            name: getNameFromUserObject(userJson),
            organization: getCurrentOrganization(userJson.organizations),
            organizations: userJson.organizations,
            userObject: userJson,
          });

          setUserObject(userJson);
          setCurrentOrganization(
            getCurrentOrganization(userJson.organizations),
          );
          setOrganizations(userJson.organizations);

          assignContexts(userJson);
        } catch (err) {
          console.error(err);

          await logOut();
        }
      } else {
        clearUserData();
      }
    },
  );

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(authStateChanged);

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        userObject,

        organization,
        organizations,

        setCurrentOrganization,
        addNewOrganization,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export function useUserAuth() {
  const context = useContext<TAuthContext>(AuthContext);

  if (!context) {
    throw new Error("useUserAuth has to be used within <AuthContextProvider>");
  }

  return context;
}
