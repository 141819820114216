import type { Hash } from "viem";

import type { DeploymentType } from "../constants/deploymentTypes";
import type { PlanType_Type } from "../constants/rpcPlans";
import type { CustomFeeToken } from "../hooks/useCustomGasToken";
import type {
  ArbitrumOrbitAPIPayload,
  OptimismAPIPayload,
} from "../utils/getAPIPayload";
import type { ConfigurationInput, InstallationArtifact } from "./schemas";

export type GetPublishedResponse = {
  network: Network;
};

export type GetPublishedRequest = {
  slug: string;
};

export type CreateNetworkRequest = {
  organization: string;
  network: string;
  opts: OptimismAPIPayload | ArbitrumOrbitAPIPayload;
};

export interface CreateNetworkResponse {
  network: Network;
}

export type UpdateNetworkRequest = {
  organization: string;
  network: string;
  externalNodes?: boolean;
  deletionProtection?: boolean;
  private?: boolean;
};

export type UpdateNetworkResponse = {
  updated: Network;
};

export type GetNetworkResponse = {
  network: Network;
  error?: ErrorMessage;
};

export type PauseTestnetRequest = {
  organization: string;
  network: string;
};

export type DeleteTestnetRequest = {
  organization: string;
  network: string;
};

export type ListNetworksRequest = {
  organization: string;
};

export type UnpauseTestnetRequest = {
  organization: string;
  network: string;
};

export type GetNetworkRequest = {
  organization: string;
  network: string;
};

export type GetCustomerPortalRequest = {
  organization: string;
  network: string | undefined;
};

export type GetCustomerPortalResponse = {
  Url: string;
};

export type EstimateRecurringDACostResponse = {
  costInGwei: string;
  costInTia: string;
};

export type EstimateRecurringDACostRequest = {
  type: string | number;
};

export type EstimateDeploymentCostRequest = {
  type: string | number;
};

export type EstimateDeploymentCostResponse = {
  costInGwei: string;
};

export type GetNetworkStatusResponse = {
  status: Status;
};

export type GetNetworkStatusRequest = {
  organization: string;
  network: string;
};

export const Status = {
  CREATING: "CREATING",
  RUNNING: "RUNNING",
  ERRORED: "ERRORED",
  DELETING: "DELETING",
  PAUSING: "PAUSING",
  PAUSED: "PAUSED",
  RESUMING: "RESUMING",
  DELETED: "DELETED",
} as const;

export type Status = keyof typeof Status;

export const NetworkBillingPlan = {
  /**
   * @deprecated No longer offered 'FREE' plan
   */
  FREE: "FREE",
  DEVNET: "DEVNET",
  TESTNET: "TESTNET",
  SELFSERVE: "SELFSERVE",
  ENTERPRISE: "ENTERPRISE",
} as const;

export type NetworkBillingPlanENUM =
  (typeof NetworkBillingPlan)[keyof typeof NetworkBillingPlan];

export interface Network {
  /**
   * Is also the 'id'
   * @type {uuid}
   */
  network: string;
  name: string;
  rpcURL: string;
  explorerURL: string;
  faucetURL: string;
  created: number;
  status: Status;
  disabled: boolean;
  private: boolean;
  deletionProtection: boolean;
  accessKeys: boolean;
  /**
   * The DNS-friendly label for the network
   */
  slug: string;
  type: DeploymentType;
  jobs: unknown[];
  components: Component[];
  urls: NetworkURL[];
  faucetUrls: FaucetURL[];
  chains: Chain[];
  information: Information[];
  tracers: Tracer[];
  prestartJobs: PrestartJob[];
  graphs: Graph[];
  publishedViewURL: string;
  links: Link[];
  plan: NetworkBillingPlanENUM;
  trialEndUnixTime: number;
  trialDeleteUnixTime: number;
  customRpcUrl: boolean;
  customExplorerUrl: boolean;
  rpcCnameEndpoint: string;
  explorerCnameEndpoint: string;
  externalNodesEnabled: boolean;
  verified: boolean;
  logoUrl: string;
}

export interface FaucetURL {
  displayName: string;
  url: string;
}

export interface Chain {
  rpcUrl: string;
  explorerUrl: string;
  chainId: string;
  name: string;
  nativeTokenName: string;
  nativeTokenAddress: string;
  nativeSymbol: string;
  nativeTokenDecimals: string;
}

export interface Component {
  network: string;
  name: string;
  container: string;
  url: string;
  displayName: string;
  description: string;
}

export const GraphIds = {
  arb_daily_requests: "arb_daily_requests",
  arb_error_requests: "arb_error_requests",
  arb_explorer_daily_requests: "arb_explorer_daily_requests",
  arb_explorer_error_requests: "arb_explorer_error_requests",
  arb_explorer_response_time: "arb_explorer_response_time",
  arb_response_time: "arb_response_time",
  chain_avg_gas_fees: "chain_avg_gas_fees",
  chain_blocks: "chain_blocks",
  chain_contracts_total: "chain_contracts_total",
  chain_contracts: "chain_contracts",
  chain_gas_used_total: "chain_gas_used_total",
  chain_top_accounts: "chain_top_accounts",
  chain_top_contracts: "chain_top_contracts",
  chain_transactions_per_second: "chain_transactions_per_second",
  chain_transactions: "chain_transactions",
  chain_wallets: "chain_wallets",
  op_daily_requests: "op_daily_requests",
  op_daily_rpc_rps: "op_daily_rpc_rps",
  op_error_requests: "op_error_requests",
  op_explorer_daily_requests: "op_explorer_daily_requests",
  op_explorer_error_requests: "op_explorer_error_requests",
  op_explorer_response_time: "op_explorer_response_time",
  op_response_time: "op_response_time",
  op_sequencer_da_cost_incremental: "op_sequencer_da_cost_incremental",
  op_sequencer_da_cost: "op_sequencer_da_cost",
  op_sequencer_profit_incremental: "op_sequencer_profit_incremental",
  op_sequencer_profit: "op_sequencer_profit",
  op_sequencer_revenue: "op_sequencer_revenue",
  rpc_top_methods: "rpc_top_methods",
} as const;

export const NetworkGraphCategory = {
  UNKNOWN: "UNKNOWN",
  ACTIVITY: "ACTIVITY",
  ACCOUNTING: "ACCOUNTING",
  PERFORMANCE: "PERFORMANCE",
  GENERAL: "GENERAL",
} as const;

export type NetworkGraphCategory =
  (typeof NetworkGraphCategory)[keyof typeof NetworkGraphCategory];

export const NetworkGraphType = {
  CHART: "CHART",
  TABLE: "TABLE",
} as const;

export type NetworkGraphType =
  (typeof NetworkGraphType)[keyof typeof NetworkGraphType];

export type GraphIds = keyof typeof GraphIds;

export interface Graph {
  network: string;
  id: GraphIds;
  category: NetworkGraphCategory;
  type: NetworkGraphType;
  /**
   * If true the graph is incremental, total = sum of values.
   *  Otherwise is cumulative, total = latest value.
   */
  incremental: boolean;
}

export interface Information {
  network: string;
  name: string;
  value: string;
  url: string;
}

export interface Link {
  network: string;
  displayName: string;
  description: string;
  linkLabel: string;
  url: string;
}

export const PrestartJob_Status = {
  RUNNING: "RUNNING",
  COMPLETED: "COMPLETED",
  ERRORED: "ERRORED",
  NOT_IN_PROGRESS: "NOT_IN_PROGRESS",
} as const;

export type PrestartJob_StatusTypes =
  (typeof PrestartJob_Status)[keyof typeof PrestartJob_Status];

export const PrestartJob_Type = {
  STANDARD: "STANDARD",
  WAITING_FOR_PAYMENT: "WAITING_FOR_PAYMENT",
  WAITING_FOR_BILLING: "WAITING_FOR_BILLING",
  WAITING_FOR_CUSTOM_TOKEN: "WAITING_FOR_CUSTOM_TOKEN",
  WAITING_FOR_INPUTS: "WAITING_FOR_INPUTS",
} as const;

export type PrestartJob_TypeTypes =
  (typeof PrestartJob_Type)[keyof typeof PrestartJob_Type];

export interface PrestartJob {
  network: string;
  displayName: string;
  description?: string;
  status: PrestartJob_StatusTypes;
  type: PrestartJob_TypeTypes;
  amountEth: number;
  deployerPublicAddress: string;
  chainId: string;
  stripeLink: string;
  feeToken?: CustomFeeToken;
  integration: string;
  inputs: { inputs: ConfigurationInput[] };
}

export interface Tracer {
  network: string;
  displayName: string;
  description?: string;
  status?: string;
  url?: string;
  name?: string;
}

export interface GetUserResponse {
  user: User;
  organizations: Organization[];
}

export interface Organization {
  organization: string;
  name: string;
  owner: string;
  isAdmin: boolean;
}

export interface User {
  user: string;
  name: string;
  email: string;
  imageURL: string;
  onboardingFormDone: boolean;
}

export type ListNetworksResponse = {
  networks: Network[];
  error?: {
    message: string;
  };
};

export interface NetworkURL {
  displayName: string;
  name: string;
  network: string;
  url: string;
  custom: boolean;
}

export interface RpcKeyEndpoint {
  // slug of the endpoint
  id: string;
  // Name of the Endpoint
  name: string;
  // uuid
  network: string;
  chainId: string;
  // Image url of the Endpoint
  imgUrl?: string;
  deploymentType: DeploymentType;
  // enum for active / inactive
  active: boolean;
  // the usage for this endpoint
  monthlyUsage: string;
  httpEndpoint: string;
  wsEndpoint: string;
  explorerEndpoint: string;
  private: boolean;
  verified: boolean;
  externalNodesEnabled: boolean;
  /**
   * If the endpoint is owned by the organization
   */
  owner?: boolean;
}

export interface RpcKeyResponse {
  // The key itself returned from the create response
  rpcKey: RpcKey;
  // optionally returns `key limit reached` and empty rpcKey if on limited plan
  error?: ErrorMessage;
  // Full list of endpoints with those active marked with active=true
  endpoints: RpcKeyEndpoint[];
}

export interface RpcKey {
  // the name of the API key
  name: string;
  // the key itself
  key: string;
  // If the key is deactviated
  disabled: boolean;
  // created date, unix timestamp in seconds, need to convert to MS
  createdAt: string;
  // deleted date, unix timestamp in seconds, need to convert to MS
  deletedAt: string;
  // the usage across all keys
  monthlyUsage: string;
  // the active endpoints
  activeEndpointsCount: string;
}

export interface ListRpcKeysRequest {
  // the organization for which to retreive the given api keys
  organization: string;
}

export interface ListRpcKeysResponse {
  // This shouldn't be undefined, but it seems to happen occasionally
  rpcKeys: RpcKey[] | undefined;
  // account usage across all keys, can be moved to own endpoint if desired
  totalMonthlyUsage: string;
  // number of keys allowed with your given plan
  keysAllowed: string;
  // number of keys currently in use
  keysInUse: string;
  // number of total endpoints in the system
  totalEndpointsCount: string;
  // Usage limit based on the active plan.
  monthlyUsageLimit: string;
  // Price per 1M compute units. <= 0 means autoscale is disabled.
  pricePerUnitBundle: number;
  // Base monthly usage for the plan.
  baseMonthlyUsage: string;
  // Overflow usage over the usage limit.
  additionalMonthlyUsage: string;
  /**
   * The plan the current org is subscribed to
   */
  plan: PlanType_Type;
  /**
   * the spending management limit
   */
  spendManagementLimit: string;
  /**
   * Whether or not spend management is enabled
   */
  usageLimitEnabled: boolean;
  includedMonthlyUsage: string;
  // If true, discount is applied.
  discountApplied: boolean;
  // If true, usage discount is applied.
  usageDiscountApplied: boolean;
  /**
   * If true, the "plan" is ending at the end of the month
   */
  cancelledAtPeriodEnd: boolean;
}

export type CreateRpcKeyRequest = {
  // The organization of which to create the API Key for
  organization: string;
  // The name of the API Key
  name: string;
};

export type CreateRpcKeyResponse = {
  // The key itself returned from the create response
  rpcKey?: RpcKey;
  // optionally returns `key limit reached` and empty apiKey if on limited plan
  error?: ErrorMessage;
  // Unsure why this is here
  endpoints: [];
};

export interface ErrorMessage {
  message: string;
}

export type DeleteRpcKeyRequest = {
  // the org for which to delete the api key
  organization: string;
  // the access key itself to delete
  key: string;
};

export type DeleteRpcKeyResponse = {
  rpcKey?: RpcKey;
  endpoints?: [];
  error?: ErrorMessage;
};

export interface GetRpcKeyEndpointsRequest {
  // The organization of which to create the API Key for
  organization: string;
  // The id of the API Key
  key: string;
}

export interface GetRpcKeyEndpointsResponse {
  rpcKey: RpcKey;
  endpoints: RpcKeyEndpoint[];
}

export interface SetRpcKeyEndpointsRequest {
  // the org for which api key and caller user belongs to
  organization: string;
  // the access key itself to update
  key: string;
  // state to set endpoints to
  active: boolean;
  // the endpoints to set
  endpointIds: string[];
}

export interface RecreateRpcKeyRequest {
  // the org for which api key and caller user belongs to
  organization: string;
  // the access key itself to update
  key: string;
}

export type UpdateNetworkDomainsResponse = {
  rpcCnameEndpoint: string;
  explorerCnameEndpoint: string;
};

export type UpdateNetworkDomainsRequest = {
  network: string;
  organization: string;
} & (
  | UpdateNetworkDomainsRequestRpcDomain
  | UpdateNetworkDomainsRequestExplorerDomain
);

type UpdateNetworkDomainsRequestRpcDomain = {
  /**
   * Set to `""` to remove domain
   */
  rpcDomain: string;
  /**
   * Set to `""` to remove domain
   */
  explorerDomain?: never;
};

type UpdateNetworkDomainsRequestExplorerDomain = {
  /**
   * Set to `""` to remove domain
   */
  rpcDomain?: never;
  /**
   * Set to `""` to remove domain
   */
  explorerDomain: string;
};

export type OrganizationMember = {
  name: string;
  email: string;
  isAdmin: boolean;
  user: string;
  imageURL: string;
};

export type ListOrganizationMembersRequest = {
  organization: string;
};

export type ListOrganizationInvite = {
  /**
   * uuid
   */
  invite: string;
  email: string;
  /**
   * Time
   */
  created: string;
  /**
   * uuid
   */
  addedBy: string;
  addedByEmail: string;
  /**
   * Time
   */
  redeemed: string;
};

export type ListOrganizationMembersResponse = {
  members: OrganizationMember[];
  invites?: ListOrganizationInvite[];
  error?: string;
};

export const ProductFamily = {
  UNKNOWN: 0,
  NETWORK_SUBSCRIPTION: 1,
  RPC: 2,
} as const;

export type ProductFamily = (typeof ProductFamily)[keyof typeof ProductFamily];

export type CreateSubscriptionRequest = {
  organization: string;
  productFamily: ProductFamily;
  plan: "pro";
  details: string;
};

export type CreateSubscriptionResponse = {
  stripeLink: string;
};

export type ChangeSubscriptionRequest = {
  organization: string;
  productFamily: ProductFamily;
} & (
  | ChangeSubscriptionRequestNewPlan
  | ChangeSubscriptionRequestCancel
  | UndoCancellationSubscriptionRequest
);

type ChangeSubscriptionRequestNewPlan = {
  new_plan: PlanType_Type;
  cancel?: never;
  undoCancelation?: never;
};

type ChangeSubscriptionRequestCancel = {
  new_plan?: never;
  cancel: boolean;
  undoCancelation?: never;
};

type UndoCancellationSubscriptionRequest = {
  new_plan: PlanType_Type;
  cancel?: never;
  undoCancelation: true;
};

export type OrganizationDomain = {
  domain: string;
  /**
   * Unix Timestamp
   */
  created: number;
};

export type ListOrganizationDomainsRequest = {
  organization: string;
};

export type ListOrganizationDomainsResponse = {
  domains: OrganizationDomain[];
};

export type TestnetConfigFilesResponse = {
  files: File[];
};

export type File = {
  humanName: string;
  fileName: string;
  url: string;
};

export type AccessKeyNetwork = {
  slug: string;
  name: string;
  rpcURL: string;
  explorerURL: string;
  faucetURL: string;
  type: DeploymentType;
  network: string;
  logoUrl: string;
  key: string;
  monthlyTotal: string;
  monthlyUsed: string;
  private: boolean;
  verified: boolean;
  chainId: string;
};

export type DeleteAccessKeyResponse = {
  redirectLink: string;
};

export type DeleteAccessKeyRequest = {
  key: {
    key: string;
    network: string;
  };
};

export type GetNetworkComponentStatusRequest = {
  network: string;
  organization: string;
  name: string;
};

export type NetworkComponentStatus = "STARTING" | "READY";

export type GetNetworkComponentStatusResponse = {
  status: NetworkComponentStatus;
};

export type NetworkGraphDataRequest = {
  network: string;
  organization: string;
  id: string;
  duration?: number;
  /**
   * "24h", "1h", "4h", "5m"
   */
  step?: string;
};

export type NetworkGraphDataResponse = {
  title: string;
  label: string;
  range: Range;
  total: number;
  detailsUrl: string;
  unit: string;
};

export type Range = {
  labels: string[];
  values: number[];
};

export type GetChainIdUniqueResponse = {
  chainId: string;
};

export type IsChainIdUniqueRequest = {
  chain_id: number;
};

export type IsChainIdUniqueResponse = {
  unique: boolean;
};

export type GetPricingRequest = {
  organization: string;
  productFamily: ProductFamily;
};

export type ProductItem = {
  // The name of the plan.
  plan: string;
  // The names of the item.
  item: string;
  // The price in usd cents. Flat or per unit based on item.
  price: number;
  // Applicable to RPC product family, flat item.
  rpcIncludedUnits: number;
  // Max number of RPC keys.
  rpcMaxKeys: number;
  // If true, discount applied to this item.
  discountApplied: boolean;
  // If true, this is an item of an active plan.
  active: boolean;
};

export type GetPricingResponse = {
  items: ProductItem[];
};

export type RemoveOrganizationMemberRequest = {
  organization: string;
  user: string;
};

export type RemoveOrganizationMemberResponse = unknown;

export type SetUsageLimitsRequest = {
  organization: string;
  enabled: boolean;
  limit?: number;
};

export type SetUsageLimitsResponse = Record<string, never>;

export type CreateUploadUrlRequest = {
  network: string;
  fileName: string;
  contentType: string;
};

export type CreateUploadUrlResponse = {
  objectId: string;
  url: string;
};

export type GetNetworkCustomizationSettingsRequest = {
  network: string;
};

export type GetNetworkCustomizationSettingsResponse = {
  displayName: string;
  logoUrl: string;
  logoObjectId: string;
  iconUrl: string;
  iconObjectId: string;
  brandColor: string;
  darkmodeLogoUrl: string;
  darkmodeLogoObjectId: string;
  darkmodeIconUrl: string;
  darkmodeIconObjectId: string;
  ogImageUrl: string;
  ogImageObjectId: string;
  ogDescription: string;
};

export interface UpdateNetworkCustomizationSettingsRequest
  extends Partial<{
    displayName: string;
    logoObjectId: string;
    iconObjectId: string;
    brandColor: string;
    darkmodeLogoObjectId: string;
    darkmodeIconObjectId: string;
    ogImageObjectId: string;
    ogDescription: string;
  }> {
  network: string;
}

export type UpdateNetworkCustomizationSettingsResponse = Record<string, never>;

export type ListAllEndpointsRequest = {
  // The organization.
  organization: string;
};

export type ListAllEndpointsResponse = {
  endpoints: RpcKeyEndpoint[];
};

export type CreateOrganizationRequest = {
  /**
   * UUID V4
   */
  organization: string;
  name: string;
};

export type CreateOrganizationResponse = { new: Organization };

export const IntegrationStatus = {
  NOT_INSTALLED: "NOT_INSTALLED",
  INSTALLING: "INSTALLING",
  INSTALLED: "INSTALLED",
  UNINSTALLING: "UNINSTALLING",
  FAILED_TO_INSTALL: "FAILED_TO_INSTALL",
  CONFIGURATION_REQUIRED: "CONFIGURATION_REQUIRED",
  INTEGRATION_REQUESTED: "INTEGRATION_REQUESTED",
  WAITING_FOR_PRESTART_JOBS: "WAITING_FOR_PRESTART_JOBS",
  WAITING_FOR_CONFIGURATION: "WAITING_FOR_CONFIGURATION",
} as const;

export type IntegrationStatus =
  (typeof IntegrationStatus)[keyof typeof IntegrationStatus];

export type Integration = {
  installation: string;
  integration: string;
  name: string;
  description: string;
  imgUrl: string;
  author: string;
  status: IntegrationStatus;
  category: string;
  secondaryCategory: string;
  oauthRequired: boolean;
  blurb: string;
  docsUrl: string;
  websiteUrl: string;
  manageUrl: string;
  configurationUrl: string;
  failureReason: string;
  requestUrl: string;
  /**
   * If true, the user will be redirected to the requestUrl
   */
  requestNotInstall: boolean;
  installationEtaSeconds: string;
  /**
   * Unix timestamp
   */
  lastUpated: string;
  bannerUrl: string;
  slug: string;
  // prestartConfigJson: object;
  artifacts: InstallationArtifact[];
};

export type ListIntegrationsRequest = {
  organization: string;
  testnet: string;
};

export type ListIntegrationsResponse = {
  instances: Integration[];
  /**
   * If the user's org has accepted the marketplace terms
   */
  marketplaceTermsAccepted: boolean;
};

export type GetIntegrationPrestartJobsRequest = {
  testnet: string;
  integration: string;
  installation: string;
};

export type GetIntegrationPrestartJobsResponse = {
  jobs: PrestartJob[];
};

export type SubmitIntegrationConfigurationRequest = {
  testnet: string;
  integration: string;
  installation: string;
  configuration: Record<string, string>;
};

export type SubmitIntegrationConfigurationResponse = {
  integration: Integration;
};

// Stored as is in the DB, native golang json API used.
export type IntegrationPrestartConfig = {
  inputs: ConfigurationInput[];
  funding: WalletFundingConfig[];
  stripe_subscription_required: boolean;
};

export type WalletFundingConfig = {
  public_address: string;
  chain_id: string;
  amount: number;
  feeToken: CustomFeeToken;
};

export type AcceptMarketplaceTermsRequest = {
  organization: string;
};

export type AcceptMarketplaceTermsResponse = Record<string, never>;

export type GetIntegrationAuthEndpointRequest = {
  testnet: string;
  organization: string;
  integration: string;
};

export type GetIntegrationAuthEndpointResponse = {
  url: string;
};

export type InstallIntegrationRequest = {
  testnet: string;
  organization: string;
  integration: string;
};

export type InstallIntegrationResponse = {
  integration: Integration;
};

export type OrganizationInvite = {
  organizationName: string;
  organizationImageUrl: string;
  inviterImageUrl: string;
  inviterName: string;
  memberCount: number;
  errorMessage?: string;
};

export type OrganizationInviteInfoRequest = { invite: string };

export type OrganizationInviteInfoResponse = OrganizationInvite;

export type UpdateUserOrganizationRequest = {
  organization: string;
  user: string;
  isAdmin: boolean;
};

export type UpdateUserOrganizationResponse = {
  updated: OrganizationMember;
};

export type DeleteOrganizationDomainRequest = {
  domain: string;
  organization: string;
};

export type VerifyOrganizationDomainRequest = {
  domain: string;
  organization: string;
  code: string;
};

export type VerifyOrganizationDomainResponse = {
  domain: OrganizationDomain;
};

export type CreateOrganizationDomainRequest = {
  domain: string;
  organization: string;
  email: string;
};

export type CreateOrganizationInviteRequest = {
  emails: string[];
  organization: string;
};

export type RedeemOrganizationInviteRequest = {
  invite: string;
};

export type RedeemOrganizationInviteResponse = {
  organization: Organization;
};

export type UninstallIntegrationRequest = {
  testnet: string;
  organization: string;
  integration: string;
};

export type UninstallIntegrationResponse = {
  integration: Integration;
};

export const DripEthRequestNetwork = {
  sepolia: "sepolia",
  base_sepolia: "base_sepolia",
  arb_sepolia: "arb_sepolia",
  mode_sepolia: "mode_sepolia",
  zora_sepolia: "zora_sepolia",
  /**
   * @deprecated Internal do not use
   */
  sepolia_testing: "sepolia_testing",
} as const;

export type DripEthRequestNetwork =
  (typeof DripEthRequestNetwork)[keyof typeof DripEthRequestNetwork];

export type DripEthRequest = {
  address: string;
  /**
   * Leave empty to drip 0.1 by default
   */
  amount: string;
  network: DripEthRequestNetwork;
  dnsslug: string;
};

export type DripEthResponse = {
  hash: Hash;
};

export type GetIntegrationPaymentInfoRequest = {
  testnet: string;
  integration: string;
};

export type GetIntegrationPaymentInfoResponse = {
  monthlySubscriptionCents: string;
};
